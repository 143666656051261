<template>
  <div class="rounded-2xl p-4 border">
    <div class="flex justify-between items-center">
      <label :for="parsedId" class="font-bold text-xl text-secondary">{{
        props.label
      }}</label>
      <SwitchBtn
        :id="parsedId"
        :model-value="props.modelValue"
        @update:model-value="emit('update:modelValue', $event)"
      />
    </div>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { SwitchBtn } from '~/components/switch-btn';
import { computed } from 'vue';
import { getUid } from '~/utils/common';

type Props = {
  modelValue: boolean;
  id?: string;
  label: string;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null): void;
}>();

const parsedId = computed<string>(() => {
  return props.id ?? `input-${getUid()}`;
});
</script>
