<template>
  <div class="modal-content mt-8">
    <div class="flex-1">
      <SelectInput
        v-model="formData.type"
        :label="$t('post-modal.select-social-media')"
        option-label-name="label"
        option-value-name="value"
        :options="socialOptions"
        :disabled="props.disableSocialSelect || !props.isEditable"
        @update:model-value="formData.socialFiles = []"
      />
      <TextArea
        v-if="isCustomizable ? isEditable : true"
        v-model="formData.description"
        :label="$t('post-modal.post-text')"
        :placeholder="$t('post-modal.post-text')"
        :rules="[isRequired, maxLengthLimit]"
        :disabled="!props.isEditable"
      />
      <BaseInput
        v-else
        :label="$t('post-modal.post-text')"
        :placeholder="$t('post-modal.post-text')"
        :rules="[isRequired, maxLengthLimit]"
        :disabled="!props.isEditable"
        :is-textarea="true"
      >
        <Description class="mt-2" :description="formData.description" />
      </BaseInput>
      <MediaManager
        v-model="formData.socialFiles"
        :social-data="formData"
        class="mt-4"
        :is-editable="isEditable"
      />
      <div v-if="showSelectLogo">
        <div class="text-xs font-semibold text-on-surface-variant mb-1">
          {{ $t('post-modal.company-logo') }}
        </div>
        <SelectLogoModal v-model="logo" />
      </div>

      <SwitchArea
        v-model="formData.isScheduled"
        class="mt-5"
        :label="$t('post-modal.schedule-post')"
        @update:model-value="updateDates"
      >
        <div v-if="formData.isScheduled" class="mt-4">
          <DateInput
            v-model="formData.date"
            :label="$t('post-modal.select-date')"
            :rules="[isRequired]"
            :min-date="new Date()"
          />
          <DateInput
            v-model="formData.time"
            is-time-picker
            :min-date="isToday(formData.date as Date) ? new Date() : ''"
            :label="$t('post-modal.select-time')"
            :rules="[isRequired, maxHourLimit]"
          />
        </div>
      </SwitchArea>
    </div>
    <SocialPostPreview
      class="flex-1"
      :post-data="formData"
      :quadrants="props.quadrants"
      :is-editable="isEditable"
      :is-customizable="isCustomizable"
      @update:post-data="(value) => (formData = value)"
    />
  </div>
</template>

<script setup lang="ts">
import { DateInput } from '~/components/inputs/date-input';
import { SelectInput } from '~/components/inputs/select-input';
import { TextArea } from '~/components/inputs/text-area';
import { SwitchArea } from '~/components/switch-area';
import {
  ContentSocialType,
  ContentSocialTypeLabelMap,
  SocialMaxLengthMap,
} from '~/api/contents';
import { isHourPast, isRequired, maxLength } from '~/utils/validators';
import { SocialPostPreview } from '~/pages/my-posts/_components/social-post-preview';
import { computed, watch } from '#imports';
import { addHours, format, isBefore, isToday, parse } from 'date-fns';
import { BaseInput } from '~/components/inputs/_components/base-input';
import { Description } from '../../../_components/description';
import { SelectLogoModal } from '~/pages/my-posts/_components/select-logo-modal';
import { MediaManager } from '~/pages/my-posts/_components/post-modal-content/_components/media-manager';
import { PostModalContentForm } from '~/pages/my-posts/_components/post-modal-content/post-modal-content.types';

type Props = {
  socialData: PostModalContentForm;
  disableSocialSelect?: boolean;
  isEditable?: boolean;
  isCustomizable?: boolean;
  quadrants?: Box[];
};

const props = defineProps<Props>();
const logo = ref('');

const formData = defineModel<PostModalContentForm>('socialData', {
  required: true,
});

const computedDate = computed(() => formData.value.date);
const maxHourLimit = isHourPast(computedDate);
const computedSocialLimit = computed(
  () => SocialMaxLengthMap[formData.value.type as ContentSocialType],
);
const maxLengthLimit = maxLength(computedSocialLimit);

const showSelectLogo = computed(
  () => (props.isCustomizable || props.isEditable) && props.quadrants?.length,
);

const socialOptions = Object.keys(ContentSocialTypeLabelMap).map(
  (socialType) => ({
    value: socialType,
    label: ContentSocialTypeLabelMap[socialType as ContentSocialType],
  }),
);

const updateDates = (value: boolean) => {
  if (!value) {
    formData.value.time = '';
    formData.value.date = '';
  }
};

const guardTodayMinDateTime = () => {
  const parsedSelectedTime = parse(
    formData.value.time as string,
    'hh:mm aa',
    new Date(),
  );

  if (
    isToday(formData.value.date as Date) &&
    isBefore(parsedSelectedTime, new Date())
  ) {
    const scheduleSafeTime = addHours(new Date(), 1);
    formData.value.time = format(scheduleSafeTime, 'hh:mm aa');
  }
};

watch(
  () => formData.value.date,
  () => guardTodayMinDateTime(),
);
</script>

<style scoped>
.modal-content {
  @apply mt-8 flex flex-wrap gap-6;
}
.file-input {
  @apply w-full aspect-square mt-2;
}

@media only screen and (min-width: 400px) {
  .modal-content {
    @apply flex-row-reverse;
  }
  .file-input {
    @apply w-50;
  }
}
</style>
