<template>
  <button
    :class="switchClass"
    role="switch"
    type="button"
    :aria-checked="props.modelValue.toString()"
    :disabled="props.disabled"
    @click="emit('update:modelValue', !props.modelValue)"
  >
    <span class="circle" :class="{ checked: props.modelValue }"></span>
  </button>
</template>

<script setup lang="ts">
import { computed } from '#imports';

type Props = {
  modelValue: boolean;
  disabled?: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: Props['modelValue']): void;
}>();

const switchClass = computed<(string | Record<string, unknown>)[]>(() => {
  return [
    'switch-btn',
    { checked: props.modelValue, disabled: props.disabled },
  ];
});
</script>

<style scoped>
.switch-btn {
  @apply h-6 w-11 pl-px rounded-full bg-line cursor-pointer flex items-center;

  .circle {
    transition: all 0.2s ease;
    @apply block h-5 w-5 rounded-full bg-surface;

    &.checked {
      transform: translateX(1.35rem);
    }
  }

  &.checked {
    @apply bg-primary;
  }

  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  }

  &:focus-visible {
    box-shadow: 0 0 0.6rem 0.06rem rgba(0, 0, 0, 0.1);
  }
}

.disabled {
  @apply opacity-75 cursor-not-allowed;
}
</style>
